import React, { useState } from 'react';

// Material UI
import { styled, Grid, Box, Typography, FormControlLabel, Switch, IconButton } from '@material-ui/core';
import { RadioGroup, Radio, Checkbox } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

// Components
import { DeleteButton, CancelButton, SubmitButton, Section, Header, Content } from 'components/ui/forms';
import { useTextInput, useToggle } from 'hooks/forms';
import { TextField, TextArea } from 'components/ui/inputs';
import { ActionButton } from 'components/ui/buttons';
import { useNotification } from 'context/notification';



export default function QuestionForm(props) {
    const { allowDelete, defaultValues, types, hasAnswers, createdAtMsg, updatedAtMsg, onCancel, onSubmit, onDelete, saving } = props;
    
    // *** Question Fields *** //
    
    const description       = useTextInput({ defaultValue: defaultValues.description || '', maxLength: 5000 });
    const [qType, setQType] = useState(defaultValues.type || types[0]);
    const reportDescription = useTextInput({ defaultValue: defaultValues.reportDescription || '', maxLength: 50 });
    const required          = useToggle({ defaultValue: defaultValues.required ?? false });
    
    const [visibility, setVisibility] = useState(defaultValues.visibility || ''); // visible, limited
    const [products, setProducts] = useState(defaultValues.products);
    const [productsError, setProductsError] = useState(null);
    
    // *** Options Fields *** //

    const [options, setOptions] = useState(defaultValues.options || []);
    const [optionsError, setOptionsError] = useState(null);


    const { notify } = useNotification();


    const handleTypeChange = (e) => {
        setQType(
            types.find(t => String(t.value) === e.target.value)
        )
    };

    const handleOptionChange = (values) => {
        setOptions(values);
        setOptionsError(null);
    };

    const handleVisiblityChange = (event) => {
        setVisibility(event.target.value);
        setProductsError(null);
    };

    const handleProductChange = (value) => {
        setProducts(value);
        setProductsError(null);
    };


    const handleSubmit = () => {
        let formError = false;

        if (description.value.trim() === '') {
            description.setError('This field cannot be empty');
            formError = true;
        }

        if (reportDescription.value.trim() === '') {
            reportDescription.setError('This field cannot be empty');
            formError = true;
        }

        if (qType.value === '') {
            description.setError('You must pick a question type');
            formError = true;
        }

        // Validate Options if the question type supports

        if (qType.hasOptions === true) {
            if (options.length === 0 || options.every(o => o.action === 'DELETE')) {
                setOptionsError('You must have at least one option');
                formError = true
            } else {
                // Check if any of the options provided are empty
                let optionsError = false;
    
                const newOptions = options.map(o => {
                    if (o.description.trim() === '') {
                        optionsError = true;
                        return ({ ...o, error: 'This option cannot be empty' });
                    } else {
                        return o;
                    }
                })
    
                if (optionsError) {
                    setOptions(newOptions);
                    formError = true;
                }
            }
        }

        
        let questionProducts = null;

        if (visibility === 'limited') {
            questionProducts = products.filter(p => p.checked === true).map(p => p.id);

            if (questionProducts.length === 0) {
                setProductsError('You must select at least one ticket from the list. If you want this question to be visible to all customers, please select "Ask for specific ticket types only".')
                formError = true;
            }
        }


        if (formError) {
            notify.warning('Please ensure all fields are filled out correctly');
            return;
        };

        onSubmit({
            description: description.value.trim(),
            type: qType.value,
            reportDescription: reportDescription.value.trim(),
            required: required.checked,
            options: qType.hasOptions ? options : [],
            products: questionProducts
        });
    }

    return (
        <Box display='flex' justifyContent='center' width='100%'>
            <Root>

                <Grid container spacing={4}>

                    <Grid item xs={12} md={6}>
                        <Section>
                            <Header>Question Type</Header>
                            {hasAnswers
                                ? <Typography>You can't change the question format after customers have already answered.</Typography>
                                : <Typography>Select the format for your question (e.g., Short Answer, Multiple Choice).</Typography>
                            }
                            <Content>
                                <TextField value={qType.value} onChange={handleTypeChange} disabled={hasAnswers} select SelectProps={{ native: true }}>
                                    <option value='' disabled>Question Type</option>
                                    {types.map(t => (
                                        <option value={t.value}>{t.text}</option>
                                    ))}
                                </TextField>
                            </Content>
                        </Section>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Section>
                            <Header>Question Name for Reports</Header>
                            <Typography>Assign a short, recognizable name for this question. This label will only appear in your reports (e.g., Excel headers).</Typography>
                            <Content>
                                <TextField {...reportDescription.formProps} label='Short Report Name' />
                            </Content>
                        </Section>
                    </Grid>
                    <Grid item xs={12}>
                        <QuestionPrompt
                            formProps={description.formProps}
                            qType={qType.value}
                        />
                    </Grid>

                    {(qType.hasOptions === true && options !== null) && (
                        <Grid item xs={12}>
                            <Options
                                key={options.length} // Re-mount options array when options are added/removed. This allows us to autofocus newly created options.
                                options={options}
                                error={optionsError}
                                type={qType.value}
                                onOptionChange={handleOptionChange}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Section>
                            <Header>When Should This Question Be Shown?</Header>
                            {productsError
                                ? <ErrorText>{productsError}</ErrorText>
                                : <Typography>You can ask this question every time, or only when certain tickets are in the customer's cart.</Typography>
                            }
                            <Content>
                                <RadioGroup aria-label="Visibility" value={visibility} onChange={handleVisiblityChange}>
                                    <Box display='flex' flexDirection='column' mb={2} alignItems='flex-start'>
                                        <FormControlLabel value="visible" control={<Radio />} label='Ask for every order or attendee' />
                                        <SwitchSubHeader>This question will always be displayed during checkout.</SwitchSubHeader>
                                    </Box>
                                    <Box display='flex' flexDirection='column' alignItems='flex-start'>
                                        <FormControlLabel value="limited" control={<Radio />} label={'Ask for specific ticket types only'} />
                                        <SwitchSubHeader>This question will only appear if a customer purchases specific ticket types.</SwitchSubHeader>
                                    </Box>
                                </RadioGroup>
                                {visibility === 'limited' && (
                                    <Products
                                        products={products}
                                        onProductChange={handleProductChange}
                                    />
                                )}
                            </Content>
                        </Section>
                    </Grid>

                    <Grid item xs={12}>
                        <Section>
                            <Header>Make This Question Required</Header>
                            <Typography>If enabled, customers must answer this question to complete their purchase. Only use this for essential information.</Typography>
                            <Content>
                                <FormControlLabel
                                    control={<Switch {...required.formProps} />}
                                    label="Required"
                                />
                            </Content>
                        </Section>
                    </Grid>
                </Grid>

                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='flex-end' mb={4} mt={4}>
                            {allowDelete && (
                                <DeleteButton onClick={onDelete} disabled={saving}>Delete</DeleteButton>
                            )}
                            <CancelButton onClick={onCancel} disabled={saving}>Cancel</CancelButton>
                            <SubmitButton onClick={handleSubmit} disabled={saving}>Save</SubmitButton>
                        </Box>
                    </Grid>
                </Grid>

                {createdAtMsg && (
                    <Typography align='right' variant='caption'>{createdAtMsg}</Typography>
                )}
                
                {updatedAtMsg && (
                    <Typography align='right' variant='caption'>{updatedAtMsg}</Typography>
                )}

            </Root>
        </Box>
    )
}


function QuestionPrompt(props) {
    const { formProps, qType } = props;

    let header = 'Question Text';
    let subtitle = 'This is the actual question your customers will see during checkout.';
    let multiline = false;
    let placeholder = `Enter your question here (e.g., "What's your t-shirt size?").`;

    
    if (qType === 10) {
        // We can change the labels and text here in the future
        multiline = true;
    }

    return (
        <Section>
            <Header>{header}</Header>
            <Typography>{subtitle}</Typography>
            <Content>
                {multiline
                    ? <TextArea  {...formProps} placeholder={placeholder} />
                    : <TextField {...formProps} placeholder={placeholder} />
                }
            </Content>
        </Section>
    )
}


function Options(props) {
    const { options, error, type, onOptionChange } = props;

    const displayOptions = props.options.filter(o => o.action !== 'DELETE'); // Don't show deleted options

    let header = '';
    let description = '';

    switch (type) {
        case 3:
            header = 'Single Choice Options';
            description = 'Attendees will select one of the following options';
            break;
        case 4:
            header = 'Multiple Choice Options';
            description = 'Attendees will select any options below that apply';
            break;
        case 5:
            header = 'Dropdown Options';
            description = 'Attendees will select one of these options from a dropdown list';
            break;
    
        default:
            break;
    }


    const handleOptionChange = (tempId, value) => {
        const newOptions = [];
        const newValue = value.slice(0, 150);

        for (const opt of options) {
            if (opt.tempId === tempId) {
                newOptions.push({ ...opt, description: newValue, error: null, autofocus: false });
            } else {
                newOptions.push({ ...opt, autofocus: false });
            }
        }

        onOptionChange(newOptions);
    };

    const handleOptionDelete = (tempId) => {
        const newOptions = [];

        for (const opt of options) {
            if (opt.tempId !== tempId) {
                newOptions.push({ ...opt, autofocus: false });
            } else if (opt.id) {
                // This option already exists in the DB. We need to push a DELETE action to the API
                newOptions.push({ ...opt, autofocus: false, action: 'DELETE' });
            }
        }
        
        onOptionChange(newOptions);
    };

    const addOption = (position) => {
        const newOptions = options.map(o => ({ ...o, autofocus: false }));
        
        const tempId = (Math.random() + '-' + Date.now()).slice(2);

        const option = {
            id: null,
            description: '',
            tempId: tempId,
            error: null,
            action: 'CREATE',
            autofocus: true
        }

        if (typeof position === 'number') {
            newOptions.splice(position, 0, option);
        } else {
            newOptions.push(option);
        }

        onOptionChange(newOptions);
    };


    const handleKeyUp = (event) => {
        if (event.key === 'Enter') {
            // Insert the new option below the current input field
            const index = options.findIndex(o => o.tempId === event.target.id);
            addOption(index + 1);
        }
    };


    return (
        <Section>
            <Header>{header}</Header>
            {error
                ? <ErrorText>{error}</ErrorText>
                : <Typography>{description}</Typography>
            }
            <Content>
                {displayOptions.map(o => (
                    <Box display={'flex'} mb={2}>
                        <TextField
                            id={o.tempId}
                            autoFocus={Boolean(o.autofocus)}
                            value={o.description}
                            onChange={(e) => handleOptionChange(o.tempId, e.target.value)}
                            onKeyUp={handleKeyUp}
                            error={Boolean(o.error)}
                            helperText={o.error || undefined}
                        />
                        <IconButton size='medium' onClick={() => handleOptionDelete(o.tempId)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                ))}
                <ActionButton onClick={addOption}>+ Add option</ActionButton>
            </Content>
        </Section>
    )
}


function Products(props) {
    const { products, onProductChange } = props;

    const handleChange = (event) => {
        const id = Number(event.target.id);
        const checked = event.target.checked;

        onProductChange(
            products.map(p => {
                if (p.id === id) {
                    return ({ ...p, checked: checked });
                } else {
                    return ({ ...p });
                }
            })
        )
    };

    return (
        <Box mt={2} ml='20px' display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
            {products.map(p => (
                <Box key={p.id} display='flex' alignItems={'center'}>
                    <Checkbox
                        id={p.id}
                        checked={p.checked}
                        onChange={handleChange}
                    />
                    {p.type === 1
                        ? <AdmissionIcon />
                        : <AddonIcon />
                    }
                    <Typography>{p.name}</Typography>
                </Box>
                    
            ))}
        </Box>
    )
}


const Root = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(2),
    marginTop: 5,
    maxWidth: theme.breakpoints.values.md,
}));

const ErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    fontWeight: 'bold'
}));

const AdmissionIcon = styled(ConfirmationNumberIcon)(({ theme }) => ({
    height: '16px',
    width: '16px',
    marginLeft: 6,
    marginRight: 6
}));

const AddonIcon = styled(AddShoppingCartIcon)(({ theme }) => ({
    height: '16px',
    width: '16px',
    marginLeft: 6,
    marginRight: 6
}));

const SwitchSubHeader = styled(Typography)(({ theme }) => ({
    marginLeft: 31,
    marginTop: -6
}));
SwitchSubHeader.defaultProps = { variant: 'caption' };