import React, { useState, useEffect } from 'react';
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import DiscountForm from 'components/forms/DiscountForm';
import { ErrorState, LoadingState } from 'components/ui/states';
import moment from 'moment-timezone';


export default function DiscountCreator(props) {
    const { eventId, usedCodes, onSave, onCancel } = props;

    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(false);

    const [defaults, setDefaults] = useState(null);

    const { notify } = useNotification();


    useEffect(() => {
        async function init() {
            try {
                const [eventInfo, prodInfo] = await Promise.all([
                    fetchExpresso(`/apiv2/events/${eventId}`),
                    fetchExpresso(`/apiv2/events/${eventId}/products`),
                ])

                if (eventInfo.status !== 200 || prodInfo.status !== 200) {
                    return setError(true);
                }

                const event = await eventInfo.json()
                const products = await prodInfo.json()


                setDefaults({
                    scheduleEnabled: false,
                    startDate: moment({ hour: 0, minute: 0 }),
                    endDate: moment(event.end_date),
                    products: products.map(p => ({
                        id: p.product_id,
                        name: p.prod_name,
                        type: p.prod_type_id,
                        checked: false
                    }))
                })
            } catch(e) {
                setError(true);
            }
        }
        init();
    }, [eventId]);
    
    const handleSubmit = (formData) => {
        if (saving) return;
        setSaving(true);

        // Convert discount percent to decimal value
        const discountPercent = formData.discountPercent / 100 || null;

        fetchExpresso('/apiv2/discounts/', {
            method: 'POST',
            body: {
                ...formData,
                eventId,
                discountPercent
            }
        })
            .then(res => {
                if (res.status === 200) {
                    notify.success('Created new discount code');
                    onSave();
                }
                else if (res.status === 409) {
                    notify.warning('This code is already in use');
                    setSaving(false);
                }
                else {
                    notify.error('Unable to create discount');
                    setSaving(false);
                }
            })
            .catch(err => {
                notify.error('Unable to create discount code');
                setSaving(false);
            })
    }

    if (error) {
        return <ErrorState message='We encountered an issue. Please close and reopen the window to try again.' />
    }

    if (defaults === null) {
        return <LoadingState />
    }
    
    return (
        <DiscountForm
            defaults={defaults}
            used={false}
            usedCodes={usedCodes}
            onSubmit={handleSubmit}
            onCancel={onCancel}
            loading={saving}
        />
    );
}