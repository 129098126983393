import React, { useState } from 'react';

// Material-UI
import { styled, useTheme } from '@material-ui/core/styles';
import { Typography, Button, Box, Switch, FormControlLabel, Radio, Checkbox, RadioGroup, InputAdornment, Grid } from '@material-ui/core';
import CasinoIcon from '@material-ui/icons/Casino';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { Alert } from '@material-ui/lab';

// Material Date Picker
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';

// Components
import Tooltip from 'components/popups/Tooltip';
import { Section, Header, Content } from 'components/ui/forms';
import { TextField } from 'components/ui/inputs';

// Utility
import { useTextInput, useNumberInput, useToggle, useDatePicker } from 'hooks/forms';
import { generateCode } from 'utility/generate';
import { getMomentFromDateAndTime } from 'utility/validation';
import { useNotification } from 'context/notification';


/**
 * Base form component
 * Can be used for creating or editing discount codes
 * Handles all form logic including validation
 */

export default function DiscountForm(props) {

    // *** Props *** //

    const { editMode, defaults, createdAtMsg, updatedAtMsg, usedCodes, onSubmit, onCancel, loading, used, onDelete } = props;


    // *** Form Values *** //

    // Discount Code
    const code = useTextInput({ defaultValue: defaults?.code ?? '', ...codeConfig });

    // Discount Value
    const [type, setType]   = useState(defaults?.discountFlat ? '$' : '%');
    const amount            = useNumberInput({ defaultValue: defaults?.discountFlat || defaults?.discountPercent || '', ...amountConfig });

    // Usage Limits
    const usageLimitEnabled = useToggle     ({ defaultValue: Boolean(defaults?.limit) });
    const usageLimit        = useNumberInput({ defaultValue: defaults?.limit ?? '', ...limitConfig });
    const userLimitEnabled  = useToggle     ({ defaultValue: Boolean(defaults?.userLimit) });
    const userLimit         = useNumberInput({ defaultValue: defaults?.userLimit ?? '', ...limitConfig });
    const scheduleEnabled   = useToggle     ({ defaultValue: defaults?.scheduleEnabled });
    const startDate         = useDatePicker ({ defaultValue: defaults?.startDate });
    const startTime         = useDatePicker ({ defaultValue: defaults?.startDate });
    const endDate           = useDatePicker ({ defaultValue: defaults?.endDate });
    const endTime           = useDatePicker ({ defaultValue: defaults?.endDate });

    // Product Limits
    const productModeEnabled      = useToggle({ defaultValue: defaults?.products.some(p => p.checked) });
    const [products, setProducts] = useState(defaults?.products);

    const productLimitEnabled = useToggle     ({ defaultValue: Boolean(defaults?.productLimit) });
    const productLimit        = useNumberInput({ defaultValue: defaults?.productLimit ?? '', ...limitConfig });

    // Visibility
    const visible = useToggle({ defaultValue: defaults?.visible ?? true});


    // *** Errors *** //

    const [scheduleError, setScheduleError] = useState(null);
    const [productsError, setProductsError] = useState(null);


    // *** Hooks *** //

    const theme = useTheme();
    const { notify } = useNotification();


    // *** Handlers *** //

    const handleProductChange = (value) => {
        setProducts(value);
        setProductsError(null);
    };

    function handleSubmit() {
        let formError = false;

        if (code.value.length === 0) {
            code.setError('This field cannot be empty');
            formError = true;
        }

        if (usedCodes.includes(code.value.toLowerCase())) {
            code.setError('This code is already being used for this event')
            formError = true;
        }

        if (amount.value.length === 0) {
            amount.setError('Please enter a valid amount');
            formError = true;
        }

        if (type === '%' && Number(amount.value) > 100) {
            amount.setError('You can\'t set a discount greater than 100%');
            formError = true;
        }

        if (usageLimitEnabled.checked === true) {
            if (usageLimit.value === '' || usageLimit.value === '0') {
                usageLimit.setError('Please enter a valid limit');
                formError = true;
            }
        }

        if (userLimitEnabled.checked === true) {
            if (userLimit.value === '' || userLimit.value === '0') {
                userLimit.setError('Please enter a valid limit');
                formError = true;
            }
        }

        let scheduleStart = null;
        let scheduleEnd   = null;

        if (scheduleEnabled.checked === true) {
            if (
                startDate.value === null || startDate.value.isValid() === false ||
                startTime.value === null || startTime.value.isValid() === false ||
                  endDate.value === null ||   endDate.value.isValid() === false ||
                  endTime.value === null ||   endTime.value.isValid() === false
            ) {
                formError = true;
                setScheduleError(null);
            } else {
                scheduleStart = getMomentFromDateAndTime(startDate.value, startTime.value);
                scheduleEnd   = getMomentFromDateAndTime(endDate.value, endTime.value);

                if (scheduleStart >= scheduleEnd) {
                    formError = true;
                    setScheduleError('Start date must come before the end date.');
                } else {
                    setScheduleError(null);
                }
            }
        } else {
            setScheduleError(null);
        }

        let discountProducts = null;

        if (productModeEnabled.checked) {
            discountProducts = products.filter(p => p.checked === true).map(p => p.id);

            if (discountProducts.length === 0) {
                setProductsError('You must select at least one ticket from the list. If you want this discount to apply to the whole order, uncheck "Apply discount to tickets".')
                formError = true;
            }
        }

        if (productLimitEnabled.checked === true) {
            if (productLimit.value === '' || productLimit.value === '0') {
                productLimit.setError('Please enter a valid limit');
                formError = true;
            }
        }

        // Form contains invalid values. Don't submit.
        if (formError) {
            notify.warning('Please ensure all fields are filled out correctly');
            return;
        }

        // Form is good. Submit formData
        const formData = {
            code:            code.value,
            discountPercent: type === '%' ? Number(amount.value) : null,
            discountFlat:    type === '$' ? Number(amount.value) : null,
            limit:           usageLimitEnabled.checked ? Number(usageLimit.value) : null,
            userLimit:       userLimitEnabled.checked  ? Number(userLimit.value)  : null,
            startDate:       scheduleStart?.format("YYYY-MM-DD HH:mm:00") || null,
            endDate:         scheduleEnd?.format("YYYY-MM-DD HH:mm:59")   || null,
            products:        discountProducts,
            productLimit:    (productModeEnabled.checked && productLimitEnabled.checked) ? Number(productLimit.value) : null,
            visible:         visible.checked
        };
        onSubmit(formData);
    }

    const randomizeCode = () => {
        const result = generateCode(5);
        code.setValue(result);
    };


    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Box display='flex' justifyContent='center' width='100%'>
                <Box display='flex' flexDirection='column' flexGrow={1} p={[2, 4]} maxWidth={theme.breakpoints.values.sm}>

                    {used && (
                        <Box mb={4}>
                            <Alert severity='warning'>
                                This discount code has been used to purchase tickets so some values can no longer be edited. If you still need to change a locked value, consider renaming this code and creating a new one with the original name.
                            </Alert>
                        </Box>
                    )}

                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Section>
                                <Header>Discount Code</Header>
                                <Typography>Enter a code attendees can use during checkout to receive a discount.</Typography>

                                <Content>
                                    <DiscountCodeInput
                                        {...code.formProps}
                                        variant='outlined'
                                        label='Discount Code'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button color='secondary' startIcon={<CasinoIcon color='secondary' />} onClick={randomizeCode}>
                                                        Random
                                                    </Button>
                                                </InputAdornment>
                                            )
                                        }}
                                    />

                                </Content>
                            </Section>
                        </Grid>

                        <Grid item xs={12}>
                            <Section>
                                <Header>Discount Value</Header>
                                <Typography>Specify whether the discount is a percentage or a fixed amount.</Typography>

                                <Box mt={2}>
                                    <RadioInput aria-label="type" row={true} value={type} onChange={(e) => setType(e.target.value)}>
                                        <FormControlLabel value="%" disabled={used} control={<Radio />} label="Percentage" />
                                        <FormControlLabel value="$" disabled={used} control={<Radio />} label="Fixed Amount" />
                                    </RadioInput>

                                    <Box m={2} />

                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                {...amount.formProps}
                                                disabled={used}
                                                label='Discount value'
                                                InputProps={{
                                                    startAdornment: type === '$' ? <InputAdornment position="start">$</InputAdornment> : undefined,
                                                    endAdornment: type === '%' ? <InputAdornment position="end">%</InputAdornment> : undefined
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                </Box>
                            </Section>
                        </Grid>

                        <Grid item xs={12}>
                            <Section>
                                <Header>Usage Limits</Header>
                                <Typography>Control when and how many times this code can be redeemed.</Typography>

                                <Content>
                                    <Box display='flex' flexDirection='column' alignItems='flex-start' mb={2}>
                                        <FormControlLabel
                                            control={<Switch {...usageLimitEnabled.formProps} />}
                                            label="Limit Total Uses"
                                        />
                                        <SwitchSubHeader>Set the maximum number of times this code can be redeemed.</SwitchSubHeader>
                                    </Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                {...usageLimit.formProps}
                                                disabled={!usageLimitEnabled.checked}
                                                label={usageLimitEnabled.checked ? 'Limit' : 'Unlimited'}
                                                error={usageLimitEnabled.checked && usageLimit.formProps.error}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Box height={36} />

                                    <Box display='flex' flexDirection='column' alignItems='flex-start' mb={2}>
                                        <FormControlLabel
                                            control={<Switch {...userLimitEnabled.formProps} />}
                                            label="Limit Per Customer"
                                        />
                                        <SwitchSubHeader>Restrict how many times a single customer can use this discount. This is tracked by the customer's email address.</SwitchSubHeader>
                                    </Box>

                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                {...userLimit.formProps}
                                                disabled={!userLimitEnabled.checked}
                                                label={userLimitEnabled.checked ? 'Limit' : 'Unlimited'}
                                                error={userLimitEnabled.checked && userLimit.formProps.error}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Box height={36} />

                                    <Box display='flex' flexDirection='column' alignItems='flex-start' mb={2}>
                                        <FormControlLabel
                                            control={<Switch {...scheduleEnabled.formProps} />}
                                            label="Discount Schedule"
                                        />
                                        <SwitchSubHeader>Set a specific date and time range when this code can be redeemed.</SwitchSubHeader>
                                    </Box>

                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <DatePicker
                                                {...startDate.formProps}
                                                label="Start Date"
                                                disabled={scheduleEnabled.checked === false}
                                                error={scheduleEnabled.checked && startDate.formProps.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TimePicker
                                                {...startTime.formProps}
                                                label="Start Time"
                                                disabled={scheduleEnabled.checked === false}
                                                error={scheduleEnabled.checked && startTime.formProps.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <DatePicker
                                                {...endDate.formProps}
                                                label="End Date"
                                                disabled={scheduleEnabled.checked === false}
                                                error={scheduleEnabled.checked && endDate.formProps.error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TimePicker
                                                {...endTime.formProps}
                                                label="End Time"
                                                disabled={scheduleEnabled.checked === false}
                                                error={scheduleEnabled.checked && endTime.formProps.error}
                                            />
                                        </Grid>
                                    </Grid>

                                    {(scheduleEnabled.checked && scheduleError) ? <Typography color='error'>{scheduleError}</Typography> : null}

                                </Content>
                            </Section>
                        </Grid>

                        <Grid item xs={12}>
                            <Section>
                                <Header>Apply to Specific Tickets</Header>
                                {(productModeEnabled.checked && productsError)
                                    ? <ErrorText>{productsError}</ErrorText>
                                    : <Typography>By default, discounts apply to the entire order. Use this option to apply the discount to specific ticket types only.</Typography>
                                }

                                <Content>
                                    <Box display='flex' flexDirection='column' alignItems='flex-start' mb={2}>
                                        <FormControlLabel
                                            disabled={used}
                                            control={<Switch {...productModeEnabled.formProps} />}
                                            label="Apply Discount to Tickets"
                                        />
                                        <SwitchSubHeader>Choose which ticket types this discount should apply to.</SwitchSubHeader>
                                    </Box>

                                    {productModeEnabled.checked && (
                                        <Products
                                            disabled={used}
                                            products={products}
                                            onProductChange={handleProductChange}
                                            error={productsError}
                                        />
                                    )}

                                    {productModeEnabled.checked && (
                                        <Box ml='44px'>
                                            <Box display='flex' flexDirection='column' alignItems='flex-start' mb={2} mt={3}>
                                                <FormControlLabel
                                                    disabled={used}
                                                    control={<Switch {...productLimitEnabled.formProps} />}
                                                    label="Limit Items"
                                                />
                                                <SwitchSubHeader>Set the maximum number of tickets per order this discount can apply to. Note: for percentage discounts, the code will apply to the cheapest tickets first.</SwitchSubHeader>
                                            </Box>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        {...productLimit.formProps}
                                                        disabled={used || !productLimitEnabled.checked}
                                                        label={productLimitEnabled.checked ? 'Limit' : 'Unlimited'}
                                                        error={productLimitEnabled.checked && productLimit.formProps.error}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}
                                </Content>
                            </Section>
                        </Grid>

                        <Grid item xs={12}>
                            <Section>
                                <Header>Discount Status</Header>
                                <Typography>Enable or disable this discount code. Ensure all details are correct before activating the code for use.</Typography>

                                <Box display='flex' flexDirection='column' alignItems='flex-start' mt={2}>
                                    <FormControlLabel
                                        control={<Switch {...visible.formProps} />}
                                        label="Active"
                                    />
                                    <SwitchSubHeader>Toggle to make this discount code active.</SwitchSubHeader>
                                </Box>
                            </Section>
                        </Grid>
                    </Grid>

                    <Box display='flex' justifyContent='flex-end' mt={6} mb={4}>
                        {editMode && (
                            <>
                                {used ? (
                                    <Tooltip message='Discounts cannot be deleted after being used by a customer.'>
                                        <DeleteButtonDisabled variant='outlined'>Delete</DeleteButtonDisabled>
                                    </Tooltip>
                                ) : (
                                    <DeleteButton variant='outlined' onClick={onDelete} disabled={loading}>Delete</DeleteButton>
                                )}
                            </>
                        )}
                        <CancelButton variant='outlined' onClick={onCancel} disabled={loading}>Cancel</CancelButton>
                        <SaveButton variant='outlined' onClick={handleSubmit} disabled={loading}>Save</SaveButton>
                    </Box>

                    {createdAtMsg && (
                        <Typography align='right' variant='caption'>{createdAtMsg}</Typography>
                    )}
                    
                    {updatedAtMsg && (
                        <Typography align='right' variant='caption'>{updatedAtMsg}</Typography>
                    )}

                </Box>

            </Box>
        </MuiPickersUtilsProvider>
    )
}


function Products(props) {
    const { products, onProductChange, disabled } = props;

    const handleChange = (event) => {
        const id = Number(event.target.id);
        const checked = event.target.checked;

        onProductChange(
            products.map(p => {
                if (p.id === id) {
                    return ({ ...p, checked: checked });
                } else {
                    return ({ ...p });
                }
            })
        )
    };

    return (
        <Box mt={2} ml='34px' display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
            {products.map(p => (
                <Box key={p.id} display='flex' alignItems={'center'}>
                    <Checkbox
                        id={p.id}
                        checked={p.checked}
                        disabled={disabled}
                        onChange={handleChange}
                    />
                    {p.type === 1
                        ? <AdmissionIcon />
                        : <AddonIcon />
                    }
                    <Typography>{p.name}</Typography>
                </Box>
                    
            ))}
        </Box>
    )
}


const DiscountCodeInput = styled(TextField)(({ theme }) => ({
    width: '50%',
    [theme.breakpoints.down('xs')]: {
        width: '100%'
    }
}));

const RadioInput = styled(RadioGroup)(({ theme }) => ({
    marginBottom: theme.spacing(1)
}));

const SaveButton = styled(Button)(({ theme }) => ({
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main
}));

const DeleteButton = styled(Button)(({ theme }) => ({
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    marginRight: 'auto'
}));


const DeleteButtonDisabled = styled(Button)(({ theme }) => ({
    color: theme.palette.grey[400],
    borderColor: theme.palette.grey[400],
    marginRight: 'auto',
    '&:hover': {
        cursor: 'default',
        backgroundColor: 'transparent'
    }
}));
DeleteButtonDisabled.defaultProps = { variant: 'contained', disableRipple: true }

const CancelButton = styled(Button)({
    marginRight: '1rem'
});

const ErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    fontWeight: 'bold'
}));

const DatePicker = styled(KeyboardDatePicker)(({ theme }) => ({}));
DatePicker.defaultProps = {
    autoOk: true, 
    fullWidth: true,
    inputVariant: "outlined",
    format: "MM/dd/yyyy"
}

const TimePicker = styled(KeyboardTimePicker)(({ theme }) => ({}));
TimePicker.defaultProps = {
    fullWidth: true,
    inputVariant: "outlined",
    mask: "__:__ _M"
}

const SwitchSubHeader = styled(Typography)(({ theme }) => ({
    marginLeft: 48,
    marginTop: -6,
    marginBottom: 8
}));
SwitchSubHeader.defaultProps = { variant: 'caption' };


const codeConfig = {
    noSpaces: true,
    maxLength: 25
}
const amountConfig = {
    decimalPlaces: 2
}
const limitConfig = {
    maxLength: 4,
    integerOnly: true
}

const AdmissionIcon = styled(ConfirmationNumberIcon)(({ theme }) => ({
    height: '16px',
    width: '16px',
    marginLeft: 6,
    marginRight: 6
}));

const AddonIcon = styled(AddShoppingCartIcon)(({ theme }) => ({
    height: '16px',
    width: '16px',
    marginLeft: 6,
    marginRight: 6
}));