import { styled, Box } from '@material-ui/core';
import { Typography, Paper, Button, CircularProgress } from '@material-ui/core';


// The section component is mainly used inside of MUI Grids.
// If you want to nest Grids, make sure to nest a <Grid container /> inside of a <Grid item />
// You can put both the container and item prop on the same <Grid /> component, but it can create unintended spacing issues
// https://stackoverflow.com/questions/72085772/nesting-grids-in-mui-results-in-an-unwanted-row-spacing
export const Section = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2)
}));

export const Header = styled(Typography)({
    fontWeight: 'bold',
    marginBottom: 4,
    fontSize: 22
})

export const Content = styled(Box)({
    marginTop: 26
});

export const CancelButton = styled(Button)(({ theme }) => ({
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    marginLeft: theme.spacing(2),
}));
CancelButton.defaultProps = { variant: 'outlined' }

export const SubmitButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
}));
SubmitButton.defaultProps = { variant: 'contained' }

export const DeleteButton = styled(Button)(({ theme }) => ({
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    marginRight: 'auto'
}));
DeleteButton.defaultProps = { variant: 'outlined' }



export const OptionPickerItem = (props) => {
    const Container   = props.checked ? OptionActive : OptionDisabled;
    const Title       = props.checked ? TitleActive : TitleDisabled;
    const Description = props.checked ? DescriptionActive : DescriptionDisabled;
    const Icon = props.icon;

    if (props.pending) {
        return (
            <Container>
                <Box height='100%' display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <CircularProgress color='secondary' />
                </Box>
            </Container>
        )
    }

    return (
        <Container onClick={props.disabled ? undefined : props.onClick}>
            <Box display={'flex'}>
                <Icon style={{ color: props.checked ? '#fff' : '#000'}} />
                <Title>{props.title}</Title>
            </Box>
            <Description>{props.description}</Description>
        </Container>
    )
};

const OptionActive = styled(Box)(({ theme }) => ({
    height: '120px',
    border: '1.5px solid',
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 12,
    padding: theme.spacing(1)
}));

const OptionDisabled = styled(Box)(({ theme }) => ({
    height: '120px',
    border: '1.5px solid',
    borderColor: theme.palette.grey[400],
    borderRadius: 12,
    padding: theme.spacing(1)
}));

const TitleActive = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1)
}));

const TitleDisabled = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.common.black,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1)
}));

const DescriptionActive = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.white
}));

const DescriptionDisabled = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.black
}));