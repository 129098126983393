import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import DiscountForm from 'components/forms/DiscountForm';
import ConfirmAction from 'components/popups/ConfirmAction';
import moment from 'moment-timezone';


export default function DiscountEditor(props) {
    const { eventId, discountId, usedCodes, onSave, onCancel } = props;

    const [loading, setLoading] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const [init, setInit] = useState({
        status: 'loading', // loading, error, success
        defaultValues: null
    });
    
    const { notify } = useNotification();

    useEffect(() => {
        async function init() {
            const [e, d] = await Promise.all([
                fetchExpresso(`/apiv2/events/${eventId}`),
                fetchExpresso(`/apiv2/discounts/${discountId}`),
            ])

            if (e.status !== 200 || d.status !== 200) {
                throw new Error();
            }

            const event = await e.json()
            const discount = await d.json()

            const scheduleEnabled = Boolean(discount.start_date || discount.end_date);
                    
            setInit({
                status: 'success',
                used: discount.qty_used > 0,
                defaultValues: {
                    code: discount.discount_code,
                    discountPercent: Number((discount.disc_order_pct*100).toFixed(2)) || null,
                    discountFlat: discount.disc_order_amt,
                    limit: discount.qty_total_available,
                    userLimit: discount.disc_customer_max,
                    scheduleEnabled: scheduleEnabled,
                    startDate: scheduleEnabled ? discount.start_date : moment({ hour: 0, minute: 0 }),
                    endDate:   scheduleEnabled ? discount.end_date   : moment(event.end_date),
                    products: discount.disc_products.map(p => ({
                        id: p.product_id,
                        name: p.prod_name,
                        type: p.prod_type_id,
                        checked: p.discount_product_flag === 1
                    })),
                    productLimit: discount.disc_product_max,
                    visible: Boolean(discount.visible),
                },
                createdAtMsg: `Created ${moment(discount.created_at).fromNow()} by ${discount.created_by}`,
                updatedAtMsg: `Updated ${moment(discount.updated_at).fromNow()}` + (discount.updated_by ? ` by ${discount.updated_by}` : '')
            })

        }
        init().catch(e => setInit({ status: 'error', defaultValues: null }));
    }, [discountId, eventId]);

    const handleSubmit = (formData) => {
        if (loading) return;
        setLoading(true);

        // Convert discount percent to decimal value
        const discountPercent = formData.discountPercent / 100 || null;

        fetchExpresso(`/apiv2/discounts/${discountId}`, {
            method: 'PUT',
            body: { ...formData, discountPercent, eventId }
        })
            .then(res => {
                if (res.status === 200) {
                    onSave();
                }
                else if (res.status === 409) {
                    notify.warning('This code is already in use')
                    setLoading(false);
                }
                else {
                    notify.error('Unable to update your discount code ')
                    setLoading(false);
                }
            })
            .catch(err => {
                notify.error('Unable to update your discount code ')
                setLoading(false);
            })
    };

    const handleDelete = () => {
        if (loading) return;
        setLoading(true);
        setConfirmDelete(false);

        fetchExpresso(`/apiv2/discounts/${discountId}`, {
            method: 'DELETE',
            body: { eventId }
        })
            .then(res => {
                if (res.status === 200) {
                    notify.success('Deleted discount code')
                    onSave();
                }
                else if (res.status === 409) {
                    notify.warning('This code has already been used. It cannot be deleted.')
                    setLoading(false);
                }
                else {
                    notify.error('Unable to delete your discount code')
                    setLoading(false);
                }
            })
            .catch(err => {
                notify.error('Unable to delete your discount code')
                setLoading(false);
            })
    };


    // Waiting for default values to load
    if (init.status === 'loading') {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' width={1} height={1}>
                <CircularProgress />
            </Box>
        )
    }
    
    // Error loading data for product form
    if (init.status === 'error') {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' width={1} height={1}>
                <Typography align='center'>There was a problem loading your discount codes</Typography>
            </Box>
        )
    }

    return (
        <>
            <DiscountForm
                editMode
                defaults={init.defaultValues}
                createdAtMsg={init.createdAtMsg}
                updatedAtMsg={init.updatedAtMsg}
                usedCodes={usedCodes}
                onSubmit={handleSubmit}
                onCancel={onCancel}
                onDelete={() => setConfirmDelete(true)}
                loading={loading}
                used={init.used}
            />

            <ConfirmAction
                open={confirmDelete} onConfirm={handleDelete} onCancel={() => setConfirmDelete(false)}
                destructive
                title='Are you sure you want to delete this discount code?'
                confirmText='Delete'
            />
        </>
    )
}