import React from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableRow, TableCell, Typography } from '@material-ui/core';

// Utility
import { formatPrice } from 'utility/numbers';


// Table only to be used for receipts and transaction details
export default function ReceiptTable(props) {
    const { pos, products} = props;
    const { sub_total, status_financial, discount_code, disc_order_total, products_discounted, tax_name, tax_on_products, our_fee_total, cc_fee_total, total, hostTotal, host_absorbs_b4t_fees, host_absorbs_cc_fees } = props.orderDetails;

    const classes = useStyles();

    const showHostAbsorbsCC = (cc_fee_total > 0 && host_absorbs_cc_fees && pos !== true);

    return (
        <div>
           <h4 className={classes.tableHeader}>Customer Pays:</h4>

            <Table>
                <TableBody>
                    {
                        products.map((p, i) => (
                            <TableRow key={i}>
                                <TableCell className={classes.listCell}>{p.product_name + ' x ' + p.qty}</TableCell>
                                <TableCell className={classes.listCell} align='right'>{formatPrice(p.price * p.qty)}</TableCell>
                            </TableRow>
                        ))
                    }
                    {
                        ( tax_on_products || !host_absorbs_b4t_fees || !host_absorbs_cc_fees ) ? (
                            <TableRow>
                                <TableCell className={classes.subTotalCell}><b>Sub Total</b></TableCell>
                                <TableCell className={classes.subTotalCell} align='right'><b>{formatPrice(sub_total)}</b></TableCell>
                            </TableRow>
                        ) : null
                    }
                    {
                        disc_order_total ? (
                            <TableRow>
                                <TableCell className={classes.discountCell}>
                                    <Typography variant='body2'>Discount ({discount_code})</Typography>
                                    {typeof products_discounted === 'number' && (
                                        <Typography variant='caption'>on {products_discounted} {products_discounted === 1 ? 'ticket' : 'tickets'}</Typography>
                                    )}
                                </TableCell>
                                <TableCell className={classes.discountCell} align='right'>{'- ' + formatPrice(disc_order_total)}</TableCell>
                            </TableRow>
                        ) : null
                    }
                    {
                        tax_on_products ? (
                            <TableRow>
                                <TableCell className={classes.listCell}>{tax_name}</TableCell>
                                <TableCell className={classes.listCell} align='right'>{formatPrice(tax_on_products)}</TableCell>
                            </TableRow>
                        ) : null
                    }
                    {
                        !host_absorbs_b4t_fees ? (
                            <TableRow>
                                <TableCell className={classes.listCell}>Convenience Fee</TableCell>
                                <TableCell className={classes.listCell} align='right'>{formatPrice(our_fee_total)}</TableCell>
                            </TableRow>
                        ) : null
                    }
                    {
                        (!host_absorbs_cc_fees && cc_fee_total > 0) ? (
                            <TableRow>
                                <TableCell className={classes.listCell}>Credit Card Fee</TableCell>
                                <TableCell className={classes.listCell} align='right'>{formatPrice(cc_fee_total)}</TableCell>
                            </TableRow>
                        ) : null
                    }

                    <TableRow>
                        <TableCell className={classes.totalCell}><b>Total</b></TableCell>
                        <TableCell className={classes.totalCell} align='right'><b>{formatPrice(total)}</b></TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            {( host_absorbs_b4t_fees || host_absorbs_cc_fees ) && (status_financial >= 1000 && status_financial <= 1999)
                ? (
                    <div>
                        <h4 className={classes.tableHeader}>Host Absorbs:</h4>
                        <Table>
                            <TableBody>
                                {
                                    host_absorbs_b4t_fees ? (
                                        <TableRow>
                                            <TableCell className={classes.listCell}>{pos ? 'Processing Fee' : 'Convenience Fee'}</TableCell>
                                            <TableCell className={classes.listCell} align='right'>{formatPrice(our_fee_total)}</TableCell>
                                        </TableRow>
                                    ) : null
                                }
                                {
                                    showHostAbsorbsCC ? (
                                        <TableRow>
                                            <TableCell className={classes.listCell}>Credit Card Fee</TableCell>
                                            <TableCell className={classes.listCell} align='right'>{formatPrice(cc_fee_total)}</TableCell>
                                        </TableRow>
                                    ) : null
                                }
                                {
                                    hostTotal ? (
                                        <TableRow>
                                            <TableCell className={classes.totalCell}><b>Payout Amount</b></TableCell>
                                            <TableCell className={classes.totalCell} align='right'><b>{formatPrice(hostTotal)}</b></TableCell>
                                        </TableRow>
                                    ) : null
                                }
                            </TableBody>
                        </Table>
                    </div>
                )
                : null
            }

        </div>
    )
}

const useStyles = makeStyles(theme => ({
    tableHeader: {
        marginBottom: '1rem',
        ...theme.typography.subHeader
    },
    listCell: {
        border: 'none',
        paddingTop: 0
    },
    discountCell: {
        border: 'none',
        paddingTop: 0,
        color: theme.palette.success.main,
        verticalAlign: 'top'
    },
    subTotalCell: {
        border: 'none',
        borderTop: '1px solid',
        paddingBottom: theme.spacing(4)
    },
    totalCell: {
        border: 'none',
        borderTop: '1px solid'
    }
}))