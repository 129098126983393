export const generateCode = (length) => {
    const chars = '23456789ABCDEFGHJKLMNPQRSTUVWXYZ';

    if (typeof length !== 'number' || length <= 0) {
        return '';
    }

    var result = '';

    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];

    return result;
};